import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'

import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'
import languagePT from './lang/textPT.json'

import { Wrapper } from './style'

import {
  Hero,
  UniverseOsPossibilities,
  FAQComponent,
} from './sections/_index'
import RobotAdvantages from './sections/RobotAdvantages/_index'
import HowDoesItWork from './sections/HowDoesItWork/_index'
import Documents from './sections/Documentacao/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QRCode from './assets/images/robo-advisor-qr-code.png'

const PoliticasDePrivacidade = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const oneLinkLP = 'https://intergo.app/c1b04ab3'

  const openModalSeguroPix = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isModalOpen}
        isHrTop
        setIsModal={setIsModalOpen}
        title={languagePT.modal.title}
        subtitle={languagePT.modal.subtitle}
        qrCode={QRCode}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openModalSeguroPix}
        <Hero lang={languagePT.section01} />
        <HowDoesItWork lang={languagePT.section02} />
        <RobotAdvantages lang={languagePT.section03} oneLink={oneLinkLP} setIsModalOpen={setIsModalOpen} />
        <UniverseOsPossibilities lang={languagePT.section04} oneLink={oneLinkLP} setIsModalOpen={setIsModalOpen} />
        <Documents lang={languagePT.section05} />
        <FAQComponent lang={languagePT.faq} />
      </Layout>
    </Wrapper>
  )
}

export default PoliticasDePrivacidade
